import React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

const OutdoorUtilityPage = () => (
  <Layout>
    <SEO title="Outdoor Utility" />
    <div style={{ maxWidth: `300px`, marginBottom: `1.45rem` }}>
      Coming Soon
    </div>
  </Layout>
)

export default OutdoorUtilityPage
